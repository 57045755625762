<template>
  <div>
    <a-card title="库存流水">
      <a-row :gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-input-search v-model="searchForm.search" placeholder="客户名/账户名" allowClear @search="search" />
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="自定义时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker v-model="searchForm.dateRange" @change="onChangePicker" style="width: 100%;" />
          </a-form-model-item>
        </a-col>
        <a-col :span="24" style="width: 300px; margin-bottom: 12px;">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
          </a-button-group>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
          @change="tableChange">
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detial(item)">详情</a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
  import { inventoryFlowsList } from '@/api/warehouse'
  import { exportExcel } from '@/utils/excel'
  import { inventoryFlowExport } from '@/api/export'

  export default {
    name: 'SaleRecord',
    components: {
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '客户名',
            dataIndex: 'client_name',
          },
          {
            title: '产品名称',
            dataIndex: 'goods_name',
          },
          {
            title: '产品编号',
            dataIndex: 'goods_number',
          },
          {
            title: '流水类型',
            dataIndex: 'type_display',
          },
          {
            title: '变化之前数量',
            dataIndex: 'quantity_before',
          },
          {
            title: '变化数量',
            dataIndex: 'quantity_change',
          },
          {
            title: '变化之后数量',
            dataIndex: 'quantity_after',
          },
          {
            title: '创建日期',
            dataIndex: 'create_time',
            width: 170
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 80
          },
        ],
        searchForm: { page: 1, pageSize: 20 },
        pagination: { current: 1, total: 0, pageSize: 20 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
      };
    },
    computed: {
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        inventoryFlowsList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      onChangePicker(date, dateString) {
        let startDate = date[0], endDate = date[1];
        this.searchForm.start_date = startDate ? startDate.format('YYYY-MM-DD') : undefined;
        this.searchForm.end_date = endDate ? endDate.add(1, 'days').format('YYYY-MM-DD') : undefined;
        this.search();
      },

      exportExcel() {
        inventoryFlowExport(this.searchForm).then(resp => {
          exportExcel(resp.data, '库存流水数据');
        }).catch(err => {
          this.$message.error(err.response.data.error);
        });
      },

      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      detial(item) {
        let pi = {"pagename": "flow", "searchForm": this.searchForm, "pagination": this.pagination}
        this.$store.commit('setPageInfo', pi);
        this.$router.push({ path: '/warehouse/flow_detail', query: { item: JSON.stringify(item) } });
      },
    },
    mounted() {
      if (this.$store.state.pageinfo.effect && this.$store.state.pageinfo.pagename == "flow") {
          this.searchForm = this.$store.state.pageinfo.searchForm;
          this.pagination = this.$store.state.pageinfo.pagination;
          this.$store.commit('setEffect', false);
      }else {
          this.$store.commit('setEffect', false);
      }
      this.initialize();
    },
  }
</script>